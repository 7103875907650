import React, {useState} from 'react';
import classes from './MenuButton.module.scss'
import {Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {ExitToApp, Info, Menu, Settings, Support} from "@mui/icons-material";
import useAuth from "../../../../hooks/auth/useAuth";

const MenuOptions = [
  {name: 'About', Icon: Info},
]


const MenuButton: React.FC = () => {


  const {logout} = useAuth();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side: keyof typeof state, open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({...state, [side]: open});
  };

  const sideList = (side: keyof typeof state) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes['settings-header']}>
        <Settings sx={{width: '24px', ml: '1rem'}}></Settings>
        <Typography variant={'body2'}>Settings</Typography>
      </div>

      <List>
        {MenuOptions.map((value) => (
          <ListItem button key={value.name}>
            <ListItemIcon>
              <value.Icon className={classes['default-button-icon']}/>
            </ListItemIcon>
            <ListItemText primary={value.name}/>
          </ListItem>
        ))}
        <ListItem button onClick={logout}>
          <ListItemIcon>
            <ExitToApp/>
          </ListItemIcon>
          <ListItemText primary="Logout"/>
        </ListItem>

      </List>
    </div>
  );

  return (
    <>
      <IconButton onClick={toggleDrawer('right', true)}>
        <Menu className={classes['default-button-icon']}/>
      </IconButton>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {sideList('right')}
      </Drawer>
    </>
  );
}

export default MenuButton
