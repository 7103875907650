import React from 'react';
import DefaultCard from "../../ui/default-card/DefaultCard";
import classes from './CardWithLogo.module.scss'

const CardWithLogo = () => {

  return (

    <DefaultCard className={classes['card-register']}>
      <div className={classes['card-register__body']}>
        <img
          className={classes['card-register__header-icon']}
          src={`/plan-m-light.png`}
          alt="payme logo"
        />
      </div>
    </DefaultCard>

  );
};

export default CardWithLogo;
