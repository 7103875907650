import classes from './DisplayImageProfile.module.scss'
import ImageUser from "../../__atoms/ImageUser/ImageUser";
import ImageGroup from "../../__atoms/ImageGroup/ImageGroup";


interface DisplayImageProfileProps {
  type?: 'group' | 'user';
  username?: string;
  image?: string;
  imageFormat?: string,
  className?: string;
  onClick?: () => void;
}

const DisplayImageProfile = ({type, username, image, className, onClick, imageFormat}: DisplayImageProfileProps) => {

    return (
      <div className={`${classes['upload-photo-root']} ${className}`} onClick={onClick}>
        {type === 'user' &&
            <ImageUser disableName imageSize={200} username={username} profileImage={image}
                       imageFormat={imageFormat}></ImageUser>
        }
        {type === 'group' &&
            <ImageGroup className={classes['upload-photo-display']} image={`${imageFormat}${image}`}></ImageGroup>
        }
      </div>
    );
  }
;

export default DisplayImageProfile;
