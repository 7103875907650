import React from 'react';
import {Toaster} from 'react-hot-toast';
import routes from './routes';
import {useRoutes} from 'react-router';
import LayoutRoot from "./core/components/Layout/LayoutRoot";
import useAuth from "./hooks/auth/useAuth";
import SplashScreen from "./core/components/SplashScreen";
import "./App.css"
import {DndProvider, MouseTransition, Preview, TouchTransition} from 'react-dnd-multi-backend'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {TouchBackend} from 'react-dnd-touch-backend'
import ContextPreview from "./core/contexts/dnd/ContextPreview";
import OneSignal from "react-onesignal";
import 'react-loading-skeleton/dist/skeleton.css'

export const HTML5toTouch = {
  backends: [
    {
      id: 'html5',
      backend: HTML5Backend,
      transition: MouseTransition,
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: {enableMouseEvents: true},
      preview: true,
      transition: TouchTransition,
      skipDispatchOnTransition: true
    },
  ],
}


const App: React.FC = () => {
  const content = useRoutes(routes);

  const auth = useAuth();



  return (
    <div className={"app"}>
      <Toaster position="top-center"
               containerStyle={{
                 top: 100,
                 left: 20,
                 bottom: 20,
                 right: 20,
               }}/>
      <LayoutRoot>
        <DndProvider options={HTML5toTouch}>
          <Preview>
            <ContextPreview/> {/* This Context Preview is responsible by showing an image on drag*/}
          </Preview>
          {auth.isInitialized ? content : <SplashScreen/>}
        </DndProvider>
      </LayoutRoot>
    </div>
  );
};

export default App;
