import React, {FC, ReactNode, useState} from 'react';
import {QueryClientProvider, QueryClient, QueryCache, MutationCache} from "@tanstack/react-query";
import {ErrorResponse} from "../../../services/models/error-response";
import {toast} from "react-hot-toast";


interface QueryClientProviderCustomProps {
  children: ReactNode;
}

export const QueryClientProviderCustom: FC<QueryClientProviderCustomProps> = ({children}) => {
  const [queryClient] = useState(() => new QueryClient(
    {
      queryCache: new QueryCache({
        onError: (error: unknown) => {
          toast.error(`Error: ${(error as ErrorResponse).message}`)
        },
      }),
      mutationCache: new MutationCache({
        onError: (error: unknown) => {
          toast.error(`Error: ${(error as ErrorResponse).message}`)
        },
      })
    }
  ));

  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};

export default QueryClientProviderCustom;
