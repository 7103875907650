import type {FC} from 'react';
import {Box} from "@mui/material";
import React from 'react';
import LayoutRoot from "./Layout/LayoutRoot";


const SlashScreen: FC = () => (
  <LayoutRoot>
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000,
      }}
    />
  </LayoutRoot>
);

export default SlashScreen;
