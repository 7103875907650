import React from 'react';
import {IconButton} from "@mui/material";
import classes from "./ButtonManually.module.scss";
import {ListAlt} from "@mui/icons-material";

interface ButtonManuallyProps {
  handleOpen: () => void;
}

const ButtonManually = ({handleOpen}: ButtonManuallyProps) => {
  return (
    <div className={classes['button-manually']}>
      <IconButton className={classes['button-manually--hover']} type={"button"} color={"primary"} onClick={handleOpen}>
        <ListAlt className={classes['button-manually-icon']}/>
      </IconButton>
    </div>
  );
};

export default ButtonManually;
