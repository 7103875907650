import React, {useState} from 'react';
import classes from './NotificationButton.module.scss'
import {Badge, Button, Drawer, IconButton, List, Typography} from "@mui/material";
import {Group, Notifications, Receipt} from "@mui/icons-material";
import {useNavigate} from "react-router";
import {Notification} from "../../../../hooks/notification/useNotification";
import {fToNow} from "../../../../utils/formatTime";


interface NotificationButtonProps {
  notifications: Notification[]
  clearNotifications: () => void;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({notifications,clearNotifications}) => {

  const navigate = useNavigate();

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side: keyof typeof state, open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({...state, [side]: open});
  };

  const handleRedirect = (reference: string, type: string) => {

    if (type === 'NEW_RECEIPT') {
      navigate(`/receipt/${reference}`)
    }

    if (type === 'NEW_GROUP') {
      navigate(`groups/details/${reference}`);
    }
  }

  const getNotificationsNumber = () => {
    return notifications?.filter((value, index) => !value?.isDelivered)?.length;
  }


  const sideList = (side: keyof typeof state) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <div className={classes['notification-header']}>
        <Notifications sx={{width: '24px', ml: '1rem'}}></Notifications>
        <Typography variant={'body2'}>Notifications</Typography>
      </div>
      <List className={classes['list-notification-box']}>
        {notifications.map((value, index) => (
          <div className={classes['notification-box']}
               onClick={() => handleRedirect(value?.reference, value?.notificationType)} key={index}>
            <div className={classes['notification-box-header']}>
              <div>{value?.title}</div>
              {/*<div className={classes['icon-type-notification']}>*/}
              {/*  {value.notificationType === 'NEW_RECEIPT' && <Receipt></Receipt>}*/}
              {/*  {value.notificationType === 'NEW_GROUP' && <Group></Group>}*/}
              {/*</div>*/}
              {/*<Typography className={classes['text-small-gray']}*/}
              {/*            variant={'body2'}>{fToNow(value?.notificationDate)}</Typography>*/}
            </div>
            <div className={classes['notification-box-body']}>
              <div className={classes['text-medium']}>{value?.body}</div>
              {/*<div className={classes['text-small-gray']}>{value.userFrom}</div>*/}
            </div>
          </div>
        ))}
      </List>


      {/*<Button className={classes['clear-all']} onClick={clearNotifications}> Clear all</Button>*/}
    </div>
  );

  return (
    <>
      <IconButton onClick={toggleDrawer('right', true)}>
        <Badge badgeContent={getNotificationsNumber()}>
          <Notifications sx={{color: 'white'}}></Notifications>
        </Badge>
      </IconButton>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {sideList('right')}
      </Drawer>
    </>
  );
}

export default NotificationButton
