import React, {MouseEventHandler} from 'react';
import {Button as MaterialUIButton} from "@mui/material";

interface ButtonProps {
  children: JSX.Element | string;
  type: "submit" | "button" | "reset"
  color?: "primary" | "inverted"
  className?: string;
  onClick?: MouseEventHandler;
  dataTestId?: string
}

const DefaultButton = ({children, type, onClick, className, dataTestId}: ButtonProps) => {
  return (
    <MaterialUIButton type={type} onClick={onClick} className={`${className}`} data-testid={dataTestId}>
      {children}
    </MaterialUIButton>
  );
};

export default DefaultButton;
