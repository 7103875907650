import { AxiosRequestConfig } from 'axios';

const AxiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:8080/api/',
  withCredentials: true,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
  },
};

export { AxiosConfig };
