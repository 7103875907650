import {AxiosError, AxiosResponse} from 'axios';
import {toast} from 'react-hot-toast';
import {ErrorResponse} from "../../models/error-response";

async function responseParser(
  response: AxiosResponse
): Promise<ErrorResponse | any> {
  try {
    if (response.status >= 500 && response.status <= 599) {
      toast.error(response.data.message);
    }

    if (response.status < 200 || response.status > 299) {
      return Promise.reject(response.data);
    }

    return Promise.resolve(response.data);
  } catch (e) {
    return Promise.reject({
      message: 'Oops, something went wrong. Please try again later.',
    });
  }
}

async function errorParser(error: AxiosError): Promise<ErrorResponse | any> {
  try {
    if (error.message === 'Network Error') {
      return Promise.reject({
        message: 'Please check your connection and try again',
      });
    }
    if (!error.response) {
      return Promise.reject({
        message: 'Oops, something went wrong. Please try again later.',
      });
    }

    if (error.response.status === 403) {
      window.history.back();
      toast.error(error.response.data.message);
    }


    if (error.response.status === 401) {
      return Promise.reject("You are not authorized to access this resource");
    }

    if (error.response.status === 500) {
      toast.error(error.response.data.message);
    }

    if (error.response.status < 200 || error.response.status > 299) {
      return Promise.reject(error.response.data);
    }
  } catch (e) {
    return Promise.reject({
      message: 'Oops, something went wrong. Please try again later.',
    });
  }
}

const isLoginRequest = (error: AxiosError): boolean => {
  return error.response?.config.url === 'auth/signin';
}

export {responseParser, errorParser};
