import {
  CreateReceiptRequest, Product, Receipt, UploadReceiptRequest,
} from './receiptModel';
import {get, post} from "../core";
import {ErrorResponse} from "../models/error-response";


export const receiptApi = () => {

  const URL_RECEIPT = '/v1/receipt'

  const create = (
    request: CreateReceiptRequest
  ): Promise<Receipt & ErrorResponse> => {
    return post(`${URL_RECEIPT}/create`, request);
  };

  const getReceiptDetails = (id?: string | string[]): Promise<Receipt & ErrorResponse> => {

    try {
      const idNumber = Number(id);
      return get(`${URL_RECEIPT}/details`, {id: idNumber});
    } catch (e) {
      throw new Error("Something went wrong " + e)
    }
  };


  const uploadReceipt = (request: UploadReceiptRequest): Promise<Product[] & ErrorResponse> => {
    try {
      return post(`${URL_RECEIPT}/upload`, request);
    } catch (e) {
      throw new Error("Something went wrong " + e)
    }
  };

  return {
    create,
    getReceiptDetails,
    uploadReceipt
  };
};
