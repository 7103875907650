import React from 'react'
import {useCallback, useContext} from "react";
import {ApplicationConfigsContext} from "./context";
import {useLocalStorage} from "../helpers/useLocalStorage";
import {Language} from "../models/language";

export type Theme = 'dark' | 'light';

export type ViewGroup = 'module' | 'list';

interface AuthenticationProviderProps {
  children: JSX.Element;
}


const useApplicationConfigs = () => useContext(ApplicationConfigsContext);

const ApplicationConfigs = ({children}: AuthenticationProviderProps) => {

  const defaultConfig: { theme: Theme, language: Language, viewGroups: ViewGroup } = {
    theme: 'light',
    language: Language.English,
    viewGroups: 'module',
  };

  //TODO: Notifications are not a core feature for beta version, should be added later
  // const {notifications, clearNotifications} = useNotification();
  const notifications: never[] = []
  const clearNotifications = () => void 0;

  const [config, setConfig] = useLocalStorage("config", defaultConfig);


  const changeLanguage = useCallback((newLanguage: Language) => setConfig({
    ...config,
    language: newLanguage
  }), [config, setConfig]);

  const handleViewGroups = useCallback((newViewGroups: ViewGroup) => setConfig({
    ...config,
    viewGroups: newViewGroups
  }), [config, setConfig]);


  /** *
   * CHANGE DARK VS LIGHT MODE
   *
   * This function is not working, I cant change between themes. To enable dark mode you should go to _app.tsx
   * and change it there
   * */
  const changeTheme = useCallback(() => {
    if (config.theme === 'light') {
      setConfig({...config, theme: "dark"})
    } else {
      setConfig({...config, theme: "light"})
    }
    console.log("setConfig")
    console.log(config.theme);
  }, [config, setConfig])


  return (
    <ApplicationConfigsContext.Provider
      value={{
        language: config.language,
        viewGroups: config.viewGroups,
        theme: config.theme,
        notifications: notifications || [],
        changeLanguage,
        handleViewGroups,
        changeTheme,
        clearNotifications
      }}
    >
      {children}
    </ApplicationConfigsContext.Provider>
  );
};

export {ApplicationConfigs, useApplicationConfigs};
