import type {FC, ReactNode} from 'react';
import React from 'react';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import useAuth from "../../hooks/auth/useAuth";
import Login from "../../pages/auth/login";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({children}) => {
  const auth = useAuth();
  const location = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string>();

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login/>;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(undefined);
    return <Navigate to={requestedLocation}/>;
  }

  return <>{children}</>;
};

export default AuthGuard;
