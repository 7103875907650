import {ReactNode, useMemo} from 'react';
import classes from './LayoutRoot.module.scss'
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import {useLocation} from "react-router-dom";
import useAuth from "../../../hooks/auth/useAuth";

interface LayoutRootProps {
  children: ReactNode
}

const LayoutRoot = ({children}: LayoutRootProps) => {

  const location = useLocation();

  const {isAuthenticated} = useAuth();

  const isFooterAvailableToShow = useMemo(() => {
    const pagesShouldHideFooter = ['/create/manual', "/create/scanned", "/create/resume"]

    if (pagesShouldHideFooter.includes(location.pathname)) {
      return false;
    }

    return isAuthenticated;
  }, [location.pathname, isAuthenticated]);

  return (
    <div className={classes["layout-root"]}>
      <div className={classes["layout-root__header"]}>
        <Header/>
      </div>
      <div
        className={isFooterAvailableToShow ? classes["layout-root__outlet"] : classes["layout-root__outlet-without-footer"]}>
        {children}
      </div>
      <div className={classes["layout-root__footer"]}>
        <Footer isVisible={isFooterAvailableToShow}/>
      </div>
    </div>
  );
};

export default LayoutRoot;
