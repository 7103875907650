import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {AxiosConfig} from '../configs';
import {errorParser, responseParser} from '../helpers';

const instance = axios.create(AxiosConfig);

const post = async <T = any>(url: string, data?: T): Promise<any> => {
  try {
    const response = await instance.post(url, data);
    return responseParser(response as any);
  } catch (error) {
    return errorParser(error as AxiosError);
  }
};

const put = async <T = any>(url: string, data?: T): Promise<any> => {
  try {
    const response = await instance.put(url, data);
    return responseParser(response as any);
  } catch (error) {
    return errorParser(error as AxiosError);
  }
};

const get = async <T = any>(url: string, params?: T): Promise<any> => {
  try {
    const response: any = await instance.get(url, {
      params,
    } as AxiosRequestConfig);
    return responseParser(response as any);
  } catch (error) {
    return errorParser(error as AxiosError);
  }
};

export {post, get, put, instance};
