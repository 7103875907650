import React from 'react';
import DefaultButton from "../../ui/default-button/DefaultButton";
import classes from "./ButtonCreateReceipt.module.scss";
import {Add} from "@mui/icons-material";

interface ButtonCreateProps {
  handleOpen: () => void;
}

const ButtonCreateReceipt = ({handleOpen}: ButtonCreateProps) => {
  return (
    <DefaultButton className={classes['default-button-create']} type={"button"} color={"primary"} onClick={handleOpen}>
      <Add className={classes['default-button-create--icon']}/>
    </DefaultButton>
  );
};

export default ButtonCreateReceipt;
