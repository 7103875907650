import React from 'react';
import classes from './DefaultCard.module.css';

interface CardProps {
    className?: string;
    fullHeight?: boolean;
    children: JSX.Element | JSX.Element[];
}

const DefaultCard = ({children, fullHeight, className}: CardProps) => {

    // eslint-disable-next-line no-useless-concat
    const allStyles = `${fullHeight && classes['fullHeight']}` + ` ${className}`

    return (
        <div className={allStyles}>
            {children}
        </div>
    );
};

export default DefaultCard;
