import { createAuthApi } from './auth/authApi';
import {groupApi} from "./groups/groupApi";
import {userApi} from "./users/userApi";
import {receiptApi} from "./receipt/receiptApi";

const api = {
  auth: createAuthApi(),
  groups: groupApi(),
  users: userApi(),
  receipt: receiptApi(),

};

export { api };
