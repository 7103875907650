import React, {FC, ReactNode, useCallback, useEffect, useReducer} from 'react';
import {instance} from '../../../services/core';
import {authenticationReducer} from './authentication-reducer';
import {
  AuthenticationContext,
  authenticationInitialState,
} from './authentication-context';
import moment from 'moment';
import {api} from "../../../services";
import {noop} from "../../../utils/noop";
// import OneSignal from "react-onesignal";

interface AuthenticationContextProps {
  children: ReactNode;
}

const TWO_MINUTES_IN_MS = 120000;
export const AuthenticationProvider: FC<AuthenticationContextProps> = ({
                                                                         children,
                                                                       }) => {
  const [authentication, dispatchAuthentication] = useReducer(
    authenticationReducer,
    authenticationInitialState
  );

  const initNotAuthenticated = useCallback(async () => {
    dispatchAuthentication({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  }, []);

  const refreshToken = useCallback(async (): Promise<void> => {
    let time;
    try {
      const {token, expiresAt} = await api.auth.refreshToken();

      time = setTimeout(() => {
        refreshToken();
      }, moment(expiresAt).diff(moment().utc()) - TWO_MINUTES_IN_MS);

      instance.defaults.headers = {Authorization: `Bearer ${token}`};
    } catch (e) {
      if (time) {
        clearTimeout(time);
      }
      throw e;
    }
  }, []);

  const initAuthenticated = useCallback(async () => {
    await refreshToken();
    const user = await api.auth.me();
    dispatchAuthentication({
      type: 'INITIALIZE',
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  }, [refreshToken]);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        await initAuthenticated();
      } catch (err) {
        await initNotAuthenticated();
      }
    };
    initialize().then(noop);
  }, [initAuthenticated, initNotAuthenticated]);

  const login = useCallback(
    async (username: string, password: string): Promise<void> => {
        const {token, expiresAt} = await api.auth.login({username, password});

        setTimeout(() => {
          refreshToken();
        }, moment(expiresAt).diff(moment().utc()) - TWO_MINUTES_IN_MS);

        instance.defaults.headers = {Authorization: `Bearer ${token}`};
        const user = await api.auth.me();

        dispatchAuthentication({
          type: 'LOGIN',
          payload: {
            user,
            token,
            expiresAt,
          },
        });
    },
    [refreshToken]
  );

  const logout = useCallback(async (): Promise<void> => {
    // await OneSignal.logout();
    await api.auth.logout();
    dispatchAuthentication({
      type: 'LOGOUT',
    });
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        ...authentication,
        platform: 'JWT',
        login,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
