import type { FC, ReactNode } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from "../../hooks/auth/useAuth";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default GuestGuard;
