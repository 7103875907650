import { useContext } from 'react';
import {TranslationsContext} from "../../core/contexts/translations/translation";
import {TranslationContext} from "../../core/contexts/translations/translation-context";


const getTranslationFromString =
  (t: TranslationsContext) =>
  (path: string): any => {
    // eslint-disable-next-line dot-notation
    const translation = t.translations[t.languageTag] || t.translations['en'];
    if (path) {
      const paths = path.split('.');
      let value = paths.reduce((a, v) => a && a[v], translation);
      if (value === undefined) {
        console.warn(`Missing translation ${path}`);
        // eslint-disable-next-line dot-notation
        value = paths.reduce((a, v) => a && a[v], t.translations['en']);
      }
      return value || path;
    }
    return translation;
  };

function useTranslation(): (path: string) => any {
  const t = useContext(TranslationContext);
  return getTranslationFromString(t);
}

export { useTranslation };
