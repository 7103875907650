import React, {useCallback} from 'react';
import {IconButton, SvgIconTypeMap} from "@mui/material";
import styles from "./FooterButton.module.scss";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";


interface FooterButtonProps {
  isActive?: boolean;
  mainRoute?: string;
  routes?: string[];
  Icon: OverridableComponent<SvgIconTypeMap>;
}

const FooterButton = ({Icon, mainRoute, routes}: FooterButtonProps) => {

  const navigate = useNavigate();
  const location = useLocation();

  const changeRoute = useCallback(() => {
    !!mainRoute && navigate(mainRoute)
  }, [mainRoute, navigate])

  const isActive = useCallback(() => {
    return mainRoute === location.pathname || routes?.includes(location.pathname);
  }, [location.pathname, mainRoute, routes])


  const shouldDisplayStyleActive = isActive() ? styles['active'] : ''

  return (
    <IconButton type={"button"} color={"primary"} className={shouldDisplayStyleActive} onClick={changeRoute}>
      <Icon className={styles['default-button-icon']}/>
    </IconButton>
  );
};

export default FooterButton;
