import {
  UserResponse, UserStatistics
} from './userModel';
import {get, post} from "../core";
import {ErrorResponse} from "../models/error-response";


export const userApi = () => {

  const URL_USER = '/v1/user'

  const getAll = (name?: string): Promise<UserResponse[] & ErrorResponse> => {
    return get(`${URL_USER}s`, {name});
  };

  const uploadImageProfile = (
    imageFile: File
  ): Promise<UserResponse & ErrorResponse> => {
    const formData = new FormData();
    formData.append('file', imageFile);
    return post(`${URL_USER}s/profile-image`, formData);
  };


  const getUserStatistics = (): Promise<UserStatistics & ErrorResponse> => {
    return get(`${URL_USER}s/statistics`);
  };

  return {
    getAll,
    uploadImageProfile,
    getUserStatistics
  };
};
