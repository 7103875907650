import React, {useCallback, useState} from 'react';
import classes from './FormLogin.module.scss'
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {
  Box, FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  TextField, Typography,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import DefaultButton from '../../ui/default-button/DefaultButton';
import useAuth from '../../../../hooks/auth/useAuth';
import {useTranslation} from "../../../../hooks/translations/useTranslation";
import {toast} from "react-hot-toast";


interface IFormInputs {
  username: string;
  password: string;
  submit: string;
}


const FormLogin = () => {

    const {login} = useAuth();

    const t = useTranslation();

    const schema = yup.object({
      username: yup.string()
        .required((t('login.required-name'))),
      password: yup.string()
        .required(t(('login.required-password'))),
    }).required();

    const {
      handleSubmit,
      control,
      setError,
      formState: {errors},
    } = useForm<IFormInputs>({resolver: yupResolver(schema)});

    const submit: SubmitHandler<IFormInputs> = useCallback(
      async (data: any) => {
        await login(data.username, data.password)
          .catch((error) => {
            toast.error(error?.message);
            setError('username', error?.message);
            setError('password', error?.message);
            setError('submit', {
              type: 'server',
              message: error?.message,
            });
          });
      },
      [login, setError]
    );

    const [showPassword, setShowPassword] = useState(false);

    const onToggleShowPassword = useCallback(() => {
      showPassword ? setShowPassword(false) : setShowPassword(true);
    }, [showPassword]);

    return (
      <div className={classes['form-login']}>
        <form noValidate onSubmit={handleSubmit(submit)}>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({field, fieldState: {isTouched, error}}) => (
              <TextField
                {...field}
                fullWidth
                error={isTouched && !!error}
                helperText={error?.message}
                label={(t('login.username-label'))}
                margin="normal"
                variant="outlined"
                autoComplete="off"
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({field, fieldState: {isTouched, error}}) => (
              <TextField
                {...field}
                fullWidth
                error={isTouched && !!error}
                helperText={error?.message}
                label={t('login.password-label')}
                margin="normal"
                variant="outlined"
                autoComplete="off"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onToggleShowPassword}
                      >
                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                <Input/>
              </TextField>
            )}
          />
          <Box>
            {errors.submit && (
              <FormHelperText error>{errors.submit.message}</FormHelperText>
            )}
          </Box>

          <Box sx={{mt: 2}}>
            <DefaultButton type={"submit"} className={classes['form-login__button']}>
              {t('login.button-login-label')}
            </DefaultButton>
          </Box>
          {/*<Box sx={{mt: 2}} onClick={loginAnonymous} className="cursor-pointer">*/}
          {/*  <Typography>{t('login.button-login-anonymous')}</Typography>*/}
          {/*</Box>*/}
        </form>
      </div>
    );
  }
;

export default FormLogin;
