import React, {useEffect, useRef} from 'react';

interface NameInitialImageProps {
  name: string;
  size?: number;
  className?: string
}

const NameInitialImage: React.FC<NameInitialImageProps> = ({name, size = 100, className}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx) {
      // Set up colors
      const backgroundColor = '#1A2036';
      const textColor = '#f4f5f7';

      // Clear the canvas with the background color
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, size, size);

      // Set up font and style
      const fontSize = Math.floor(size / 2);
      ctx.font = `${fontSize}px Monoton, Arial, sans-serif`;
      ctx.fillStyle = textColor;

      // Get the initials
      const initials = name
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');

      // Measure the text dimensions
      const textMetrics = ctx.measureText(initials);
      const textWidth = textMetrics.width;
      const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;

      // Calculate the centered position
      const textX = (size - textWidth) / 2;
      const textY = (size - textHeight) / 2 + textMetrics.actualBoundingBoxAscent;

      // Draw initials on the canvas
      ctx.fillText(initials, textX, textY);
    }
  }, [name, size]);

  return <canvas className={className} ref={canvasRef} width={size} height={size}/>;
};

export default NameInitialImage;
