import {Theme, useApplicationConfigs} from "../context/useApplicationConfigs";
import {createCustomTheme} from "../../theme";
import {ThemeProvider} from "@mui/material";
import React, {createContext} from "react";


interface ApplicationConfigsContextInterface {
  theme: Theme;
}

export const defaultThemeContext: ApplicationConfigsContextInterface = {
  theme: 'light'
};

export const ThemeContext = createContext(defaultThemeContext);


/**
 * I needed to create this ThemeContextProvider because <ThemeProvider needs a value that is on my context. For that reason
 * I needed to separate from _app.tsx. Since I'm using his provider there I could not use the consumer in the same component.
 *
 * This logic was found out here:
 * https://stackoverflow.com/questions/71516195/using-usecontext-to-set-dark-mode-for-all-pages-in-next-js-and-material-ui
 *
 */

export const ThemeContextProvider = (props: any) => {

  const {theme} = useApplicationConfigs();

  const darkTheme = createCustomTheme({
    responsiveFontSizes: true,
    direction: 'ltr',
    roundedCorners: true,
    theme: 'dark',
  });

  const lightTheme = createCustomTheme({
    responsiveFontSizes: true,
    direction: 'ltr',
    roundedCorners: false,
    theme: 'light',
  });


  return (
    <ThemeContext.Provider value={{theme}}>
      <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
