import React, { useCallback, useEffect, useState } from 'react';
import { findBestAvailableLanguage } from './translation-utils';
import { Language, Translation, TranslationProviderProps } from './translation';
import { TranslationContext } from './translation-context';

function getBrowserLanguage(
  translations: Translation,
  fallback: Language = { languageTag: 'en', isRTL: false }
) {
  return findBestAvailableLanguage(Object.keys(translations)) || fallback;
}

const TranslationProvider: React.FC<TranslationProviderProps> = ({
  translations,
  fallback,
  children,
}) => {
  const [{ languageTag, isRTL }, setLanguage] = useState(
    getBrowserLanguage(translations, fallback)
  );

  const handleLanguageChange = useCallback(() => {
    const l = getBrowserLanguage(translations, fallback);
    setLanguage(l);
  }, [translations, fallback]);

  useEffect(() => {
    window.addEventListener('languagechange', handleLanguageChange);
    return () =>
      window.removeEventListener('languagechange', handleLanguageChange);
  }, [handleLanguageChange]);

  return (
    <TranslationContext.Provider
      value={{
        languageTag,
        isRTL,
        setLanguage,
        translations,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export { TranslationProvider };
