import React, {FC} from 'react';
import classes from "./Wrapper.module.scss";

import GoBackButton from "../../GoBackButton/GoBackButton";
import {Backdrop, Fade, Modal} from "@mui/material";

interface WrapperProps {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const Wrapper: FC<WrapperProps> = ({handleClose, open, children, className}: WrapperProps) => {
  return (
    <Modal
      className={classes['wrapper']}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{invisible: true}} // remove the grey background
    >
      <Fade in={open}>
        <div className={`${className} ${classes['wrapper-paper']}`}>
          <GoBackButton handleClick={handleClose}/>
          {children}
        </div>
      </Fade>
    </Modal>
  );
};

export default Wrapper;
