import {Authentication} from './authentication';
import {AuthenticationAction} from './authentication-action';

const authenticationReducer = (
  state: Authentication,
  action: AuthenticationAction
): Authentication => {
  switch (action.type) {
    case 'INITIALIZE': {
      const {isAuthenticated, user} = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case 'LOGIN': {
      const {user} = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
  }
  return state;
};

export {authenticationReducer};
