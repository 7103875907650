import { createContext } from 'react';
import { AuthContextValue, Authentication } from './authentication';

const authenticationInitialState: Authentication = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  token: null,
  expiresAt: null,
};

const AuthenticationContext = createContext<AuthContextValue>({
  ...authenticationInitialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export { AuthenticationContext, authenticationInitialState };
