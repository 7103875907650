import React from 'react';
import {IconButton} from "@mui/material";
import styles from "./ButtonScan.module.scss"
import {FilterCenterFocus} from "@mui/icons-material";

interface ButtonScanProps {
  handleOpen: () => void;
}

const ButtonScan = ({handleOpen}: ButtonScanProps) => {
  return (
    <div className={styles['button-scan']}>
      <IconButton type={"button"} color={"primary"} onClick={handleOpen} className={styles['button-scan--hover']}>
        <FilterCenterFocus className={styles['button-scan-icon']}/>
      </IconButton>
    </div>
  );
};

export default ButtonScan;
