import classes from './Header.module.scss'
import {Card, Link, Typography} from '@mui/material';
import {useNavigate} from "react-router";
import {useApplicationConfigs} from "../../../../hooks/context/useApplicationConfigs";
import {useLocation} from "react-router-dom";
import useAuth from "../../../../hooks/auth/useAuth";
import {useTranslation} from "../../../../hooks/translations/useTranslation";
import DisplayImageProfile from "../../__molecules/DisplayImageProfile/DisplayImageProfile";
import NotificationButton from "../NotificationButton/NotificationButton";

const Header = () => {

  const {notifications, clearNotifications} = useApplicationConfigs();

  const {isAuthenticated, user} = useAuth();

  const t = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();

  const currentRoute = location.pathname.substring(1);


  return (
    <Card className={classes['header']}>
      <div className={classes['header__logo']}>
        <img
          src={`/plan-m-light.png`}
          alt="logo"
          onClick={() => navigate("/")}
        />
      </div>
      <div className={classes['header__body']}>
        {!isAuthenticated ?
          <>
            {currentRoute && currentRoute === 'register' ?
              <>
                <Typography variant={'subtitle2'}
                            className={classes['header__label']}> {t(`register.button-text`)} </Typography>
                <Link href={'login'}>
                  <Typography variant={'subtitle2'} className={classes['header__label-link']}>
                    &nbsp;{(t(`register.button-label`))}</Typography>
                </Link>
              </>
              :
              <>
                <Typography variant={'subtitle2'}
                            className={classes['header__label']}> {t(`login.button-text`)} </Typography>
                <Link href={'register'}>
                  <Typography variant={'subtitle2'} className={classes['header__label-link']}>
                    &nbsp;{(t(`login.button-label`))}</Typography>
                </Link>
              </>
            }
          </>
          :
          <div className={classes['header-body__info']}>
            <NotificationButton notifications={notifications}
                                clearNotifications={clearNotifications}></NotificationButton>
            <DisplayImageProfile type={'user'}
                                 username={user?.username}
                                 imageFormat={'data:image/jpeg;base64,'}
                                 image={user?.profileImage}
                                 className={classes['header-body__info-image']}
                                 onClick={() => navigate("/profile")}
            ></DisplayImageProfile>
          </div>
        }
      </div>
    </Card>
  );
};

export default Header;
