import React from "react";
import classes from "./LoginTemplate.module.scss"
import CardWithLogo from "../../__atoms/CardWithLogo/CardWithLogo";
import FormLogin from "../../__organisms/FormLogin/FormLogin";
import Camera from "../../Camera/Camera";

const LoginTemplate = () => {
  return (
    <div className={classes['login-page']}>
      <div className={classes['login-page__body']}>
        <CardWithLogo/>
        <FormLogin/>
      </div>
    </div>)
}

export default LoginTemplate;
