import React, {useState} from 'react';
import classes from './CreateReceipt.module.scss'
import FormCreateReceipt from "./FormCreateRecipt/FormCreateReceipt";
import ButtonCreateReceipt from "./ButtonCreateReceipt/ButtonCreateReceipt";

const CreateReceipt: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes['create-group-form']}>
      <ButtonCreateReceipt handleOpen={handleOpen}/>
      <FormCreateReceipt open={open} handleClose={handleClose}/>
    </div>
  );
};

export default CreateReceipt;
