import React from 'react';
import styles from './Footer.module.scss'
import FooterButton from "../FooterButton/FooterButton";
import MenuButton from "../MenuButton/MenuButton";
import CreateReceiptButton from "../../CreateReceipt/CreateReceipt";
import {Home, People, Person} from "@mui/icons-material";
import {Fragment} from "preact";

interface FooterProps {
  isVisible: boolean
}

const Footer = ({isVisible}: FooterProps) => {

  return (
    <Fragment>
      {isVisible &&
        <div className={styles['footer']}>
          <FooterButton isActive={true} Icon={Home} mainRoute={"/home"}/>

          <FooterButton Icon={People} mainRoute={"/groups"} routes={["/group/details/[id]"]}/>

          <CreateReceiptButton/>

          <FooterButton Icon={Person} mainRoute={"/profile"}/>

          <MenuButton/>
        </div>
      }
    </Fragment>
  )
}

export default Footer;
