import React, {lazy, useEffect} from 'react';
import GuestGuard from "./core/auth/GuestGuard";
import AuthGuard from "./core/auth/AuthGuard";
import {Loadable} from "./utils/loadable";

const Login = Loadable(
  lazy(() => import('./pages/auth/login'))
);
const Register = Loadable(
  lazy(() => import('./pages/auth/register'))
);
const Home = Loadable(lazy(() => import('./pages/home')));

const Groups = Loadable(lazy(() => import('./pages/groups')));

const CreateManualReceipt = Loadable(lazy(() => import('./pages/create/manual')));

const CreateScannedReceipt = Loadable(lazy(() => import('./pages/create/scanned')));

const GroupDetails = Loadable(lazy(() => import('./pages/group/details')));

const ReceiptDetails = Loadable(lazy(() => import('./pages/receipt')));

const Profile = Loadable(lazy(() => import('./pages/profile')));

const RedirectToHome = () => {
  // You can put any logic you want here before redirecting
  useEffect(() => {
    window.location.href = "/home"; // Redirect to the desired URL
  }, []);
  return null;
};

const routes: any[] = [
  {
    path: '',
    element: (
      <AuthGuard>
        <RedirectToHome/>
      </AuthGuard>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login/>
      </GuestGuard>
    ),
  },
  {
    path: 'register',
    element: (
      <GuestGuard>
        <Register/>
      </GuestGuard>
    ),
  },
  {
    path: '/',
    children: [
      {
        path: '/home',
        element: (
          <AuthGuard>
            <Home/>
          </AuthGuard>
        ),
        breadcrumb: 'Home',
      },
      {
        path: '/profile',
        element: (
          <AuthGuard>
            <Profile/>
          </AuthGuard>
        ),
        breadcrumb: 'Home',
      },
      {
        path: '/groups',
        element: (
          <AuthGuard>
            <Groups/>
          </AuthGuard>
        ),
        breadcrumb: 'Groups',
      },
      {
        path: 'groups/details/:groupId',
        element: (
          <AuthGuard>
            <GroupDetails/>
          </AuthGuard>
        )
      },
      {
        path: '/create/manual',
        element: (
          <AuthGuard>
            <CreateManualReceipt/>
          </AuthGuard>
        )
      },
      {
        path: '/create/scanned',
        element: (
          <AuthGuard>
            <CreateScannedReceipt/>
          </AuthGuard>
        )
      },
      {
        path: '/receipt/:receiptId',
        element: (
          <AuthGuard>
            <ReceiptDetails/>
          </AuthGuard>
        )
      },
    ],
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <div>not found</div>
      </AuthGuard>
    ),
  },
];

export default routes;
