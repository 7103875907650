import { createContext } from 'react';
import { TranslationsContext } from './translation';
import {noop} from "../../../utils/noop";

const TranslationContext = createContext<TranslationsContext>({
  languageTag: 'en',
  isRTL: false,
  setLanguage: noop,
  translations: {},
});

export { TranslationContext };
