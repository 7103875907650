import classes from './ImageGroup.module.scss'
import {Fragment} from "preact";

interface GroupProfileProps {
  className?: string;
  image?: string;
  imageFormat?: string;
}

const ImageGroup = ({className, image, imageFormat}: GroupProfileProps) => {

  return (
    <div className={`${classes['root']} ${className}`}>
      {image ?
        <img
          alt="group image"
          src={`${imageFormat}${image}`}
        />
        :
        <img className={classes['group-photo']}
             alt="group-photo" src={`/group-photo-light.png`}/>
      }
    </div>
  );
};

export default ImageGroup;
