import {AuthRequest, AuthResponse, RegisterRequest, RegisterResponse} from './authModel';
import {ErrorResponse} from "../models/error-response";
import {User} from "../../core/models/user";
import {get, post} from "../core";

export const createAuthApi = () => {

  const login = (
    credentials: AuthRequest
  ): Promise<AuthResponse & ErrorResponse> => {
    return post('/auth/signin', credentials);
  };

  const me = (): Promise<User & ErrorResponse> => {
    return get('/auth/me');
  };

  const refreshToken = (): Promise<AuthResponse & ErrorResponse> => {
    return get('/auth/refresh-token');
  };

  const register = (
    credentials: RegisterRequest
  ): Promise<RegisterResponse & ErrorResponse> => {
    return post('/auth/register', credentials);
  };

  const logout = (): Promise<ErrorResponse> => {
    return post('/auth/signout');
  };

  return {
    refreshToken,
    login,
    me,
    register,
    logout,
  };
};
