import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {ThemeContextProvider} from "./hooks/helpers/ThemeContextProvider";
import AuthenticationProvider from "./core/contexts/authentication/authentication-provider";
import {TranslationProvider} from "./core/contexts/translations/translation-provider";
import en from './services/translations/en.json';
import {ApplicationConfigs} from "./hooks/context/useApplicationConfigs";
import QueryClientProviderCustom from "./core/contexts/react-query/react-query-provider";
import './index.scss';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

root.render(
  // <React.StrictMode>
  <TranslationProvider translations={{en}}>
    <AuthenticationProvider>
      <QueryClientProviderCustom>
        <ApplicationConfigs>
          <ThemeContextProvider>
            <BrowserRouter>
              <App/>
            </BrowserRouter>
          </ThemeContextProvider>
        </ApplicationConfigs>
      </QueryClientProviderCustom>
    </AuthenticationProvider>
  </TranslationProvider>
  // </React.StrictMode>
);
