import {
  CreateGroupRequest,
  Group,
} from './groupModel';
import {post, get} from "../core";
import {ErrorResponse} from "../models/error-response";


export const groupApi = () => {

  const URL_GROUP = '/v1/group'

  const create = (
    request: CreateGroupRequest
  ): Promise<Group & ErrorResponse> => {
    const formData = new FormData();
    if (request.groupImage) {
      formData.append('groupImage', request.groupImage);
    }
    formData.append('name', request.name);
    formData.append('splitPreference', request.splitPreference);
    formData.append('users', JSON.stringify(request.users));
    return post(`${URL_GROUP}/create`, formData);
  };

  const getAll = (): Promise<Group[] & ErrorResponse> => {
    return get(`${URL_GROUP}s`);
  };

  const getGroupDetails = (request: { name?: string | string[] }): Promise<Group & ErrorResponse> => {
    return get(`${URL_GROUP}/details`, request);
  };


  return {
    create,
    getAll,
    getGroupDetails
  };
};
