// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function findBestAvailableLanguage(availableLanguages: string[]) {
  const browserLanguages = navigator.languages;
  if (navigator.languages) {
    for (const bl in browserLanguages) {
      for (const al in availableLanguages) {
        if (browserLanguages[bl] === availableLanguages[al]) {
          return {
            languageTag: browserLanguages[bl],
            isRTL: false,
          };
        }
      }
    }
  }
}

export { findBestAvailableLanguage };
