import React from 'react';
import classes from './GoBackButton.module.scss'
import {IconButton} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";

interface GoBackButtonProps {
  handleClick: () => void;
}

const GoBackButton = ({handleClick}: GoBackButtonProps) => {
  return (
    <IconButton className={classes['go-back-button']} onClick={handleClick}>
      <ArrowBackIos className={classes['back-button']}/>
    </IconButton>
  );
};

export default GoBackButton;
