import {createContext} from "react";
import {Theme, ViewGroup} from "./useApplicationConfigs";
import {Language} from "../models/language";
import {Notification} from "../notification/useNotification";

interface ApplicationConfigsContextInterface {
  language: Language;
  changeLanguage: (language: Language) => void;
  viewGroups: ViewGroup,
  handleViewGroups: (viewGroup: ViewGroup) => void;
  changeTheme: () => void;
  clearNotifications: () => void;
  theme: Theme;
  notifications: Notification[];
}

export const defaultApplicationConfigsContext: ApplicationConfigsContextInterface = {
  language: Language.English,
  changeLanguage: () => void 0,
  viewGroups: 'list',
  handleViewGroups: () => void 0,
  theme: "light",
  changeTheme: () => void 0,
  clearNotifications: () => void 0,
  notifications: []
};

export const ApplicationConfigsContext = createContext(defaultApplicationConfigsContext);
