import classes from './ImageUser.module.scss'
import NameInitialImage from "../../NameInitialImage/NameInitialImage";
import {Tooltip} from "@mui/material";


interface ImageUserProps {
  disableName?: boolean;
  imageSize?: number;
  username?: string;
  profileImage?: string;
  imageFormat?: string,
}


const ImageUser = ({username, profileImage, disableName, imageSize, imageFormat}: ImageUserProps) => {


  return (
    <div className={classes['root']} aria-label={username}>
      {profileImage ?
        <img
          alt="user profile"
          src={`${imageFormat}${profileImage}`}
        ></img>
        :
        <NameInitialImage name={username || 'New User'} className={classes['image']} size={imageSize}/>
      }
      {!disableName && <p>{username}</p>}
    </div>
  );
};

export default ImageUser;
