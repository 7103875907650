import React from 'react';
import classes from "./FormCreateReceipt.module.scss";
import Wrapper from "../../ui/wrapper/Wrapper";
import ButtonManually from "../ButtonManually/ButtonManually";
import ButtonScan from "../ButtonScan/ButtonScan";
import {useNavigate} from "react-router";


interface FormCreateReceiptProps {
  handleClose: () => void;
  open: boolean;
}

const FormCreateReceipt = ({handleClose, open}: FormCreateReceiptProps) => {

  const navigate = useNavigate();


  const handleOpenManually = () => {
    navigate("/create/manual");
    handleClose()
  };

  const handleOpenScanned = () => {
    navigate("/create/scanned");
    handleClose()
  };


  return (
    <Wrapper handleClose={handleClose} open={open} className={classes['form-create-receipt']}>
      <ButtonScan handleOpen={handleOpenScanned}/>
      <ButtonManually handleOpen={handleOpenManually}/>
    </Wrapper>
  );
};

export default FormCreateReceipt;
