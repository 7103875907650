import React, {useContext} from "react";
import {PreviewContext, PreviewState} from "react-dnd-multi-backend";

export type DragContent = {
  color: string
}

const ContextPreview = () => {
  const preview = useContext(PreviewContext)
  if (!preview) {
    throw new Error('missing preview context')
  }
  const {style} = preview as PreviewState<DragContent>

  //TODO check item type.

  return <img src={"/default-food.jpg"} style={{...style, zIndex: 9999, width:"40px"}} alt={"default-food"}/>
}

export default ContextPreview;
